<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Доработка лица</h3>
        </div>

        <VideoView video-id="ecfb58ca5cf84b86b2a9c19f9dc12a65"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Доработать детали на лице</li>
                <li>Проверить форму головы на симметричность. Сверху она должна напоминать яйцо.</li>
            </ol>
            
            <h3>2. Шарнироприемник в ноге + доработка стопы</h3>
        </div>

        <VideoView video-id="6ced987f60b247a88b1c44e062921ddc"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Утопить шарнир на 1/2 в ноге</li>
                <li>Подредактировать форму стопы</li>
            </ol>

            <h3>3. Шарнироприемник в руке + доработка кисти</h3>
        </div>
        
        <VideoView video-id="f151652f59ab4884b3e602b0ad3f00f2"/>        

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Утопить шарнир на 1/3 в руке</li>
            </ol>

            <h3>4. Шарнироприемник в голове + ухо</h3>
        </div> 

        <VideoView video-id="9b5d9f45ebc546519b007d1aed63564a"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Сделать шарнироприемник в голове по разметке</li>
                <li>Слепить 2 симметричных уха</li>
            </ol>

            <a @click="showImage('/files/BodoirV2/Ear.jpg', 'swal-image2')" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-ear.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>5. Долепливаем нижнюю часть тела</h3>
        </div>
                
        <VideoView video-id="fcf0e710479d47eb846d6e24a21ec70c"/>

        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Шарнироприемник в ногах, руках, голове</li>
                <li>Слепить ухо</li>
                <li>Долепить нижнюю часть тела</li>
                <li><b>Подготовить материалы</b> к следующему занятию: </li>
                <ol type="a">
                    <li>толстый провод в оплетке (медный провод 10см в длину, сечение 2,5мм)</li>
                    <li>плоский надфиль толщиной 2-2.5мм</li>
                    <li>скрепки нерефленный 5см (из них мы сделаем перемычки и крючки)</li>
                    <li>сверло толщиной в перемычку (1-1.5мм) + сверло 1.5-2мм</li>
                    <li>резинка толщиной 3–4мм длиной 30см-40см</li>
                    <li>резинка толщиной 2мм длиной 110см</li>
                    <li>тонкая замша или кожа телесного цвета (толщиной 0,3-0,6мм)</li>
                    <li>эпоксидный клей</li>
                    <li>малярный скотч</li>
                </ol>
            </ol>
           <h3>Время выполнения: 5 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>